(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/url-mapping/helpers/url-mapping-handlebar-helper.js') >= 0) return;  svs.modules.push('/components/core/url-mapping/helpers/url-mapping-handlebar-helper.js');

(function() {
  'use strict';

  function registerHelper(hbs, urlMapping) {
    hbs.registerHelper('urlMapping', function(id) {
      return urlMapping.get(id);
    });
  }

  if (typeof exports === 'object') {
    var path = require('path');
    var trinidadCore = require(path.join(__dirname, '..', '..', '..', '..', 'core')).core;

    registerHelper(require('hbs'), trinidadCore.urlMapping);
  } else {
    registerHelper(Handlebars, svs.core.urlMapping);
  }
})();


 })(window);