(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/url-mapping/assets/javascripts/url-mapping.js') >= 0) return;  svs.modules.push('/components/core/url-mapping/assets/javascripts/url-mapping.js');

svs = svs || {};

(function(svs) {
  'use strict';

  svs.core = svs.core || {};

  svs.core.urlMapping = (function() {
    function get(id) {
      return (
        svs.core.data.selectedUrlMappings &&
        svs.core.data.selectedUrlMappings[id]
      );
    }

    return {
      get: get
    };
  })();
})(svs);


 })(window);